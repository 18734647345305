// extracted by mini-css-extract-plugin
export var heading = "add-module--heading--3FNN5";
export var form = "add-module--form--9VjvV";
export var formLabel = "add-module--formLabel--3sfvY";
export var formLabelValue = "add-module--formLabelValue--2sPhP";
export var formInput = "add-module--formInput--2Lvst";
export var formInputError = "add-module--formInputError--2GbHH";
export var formInputSelect = "add-module--formInputSelect--3lALR";
export var formInputSelectError = "add-module--formInputSelectError--2-rPE";
export var formInputMultipleContainer = "add-module--formInputMultipleContainer--2D5vW";
export var formInputMultipleSeparator = "add-module--formInputMultipleSeparator--2enKn";
export var formInputErrorMessage = "add-module--formInputErrorMessage--xrTlf";
export var formSubmit = "add-module--formSubmit--25G7g";